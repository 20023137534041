export function displayNumberAsCurrency(num, precision, thousandsSeparator = true) {
    if (num != null) {
        const formattedNumber = num.toFixed(precision).replace(".", ",");
        if (thousandsSeparator) {
            const commaOrNothing = (formattedNumber === null || formattedNumber === void 0 ? void 0 : formattedNumber.includes(",")) ? "," : "";
            const regex = new RegExp("(\\d)(?=(\\d{3})+(?!\\d)" + commaOrNothing + ")", "g");
            return formattedNumber === null || formattedNumber === void 0 ? void 0 : formattedNumber.replace(regex, "$1.");
        }
        else {
            return formattedNumber;
        }
    }
    else {
        return undefined;
    }
}
